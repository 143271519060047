@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Quicksand", sans-serif;
}

.input-group {
  margin-bottom: 2rem;
  display: block;
}

.input-group h2 {
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
}

.input-group input,
.input-group textarea {
  border: 1px solid #e5e5e5;
  background: transparent;
  font-size: 1.2rem;
  padding: 0.5rem;
  width: 100%;
  color: #333;
  border-radius: 5px;
}
